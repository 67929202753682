/**
 * If adding new a Source Type, please update \Cotd\EntityBundle\Model\CartItem
 *
 * @format
 */
export var SOURCE_TYPE_EVENT = 1;
export var SOURCE_TYPE_SEARCH = 2; // const SOURCE_TYPE_INSTANT_SEARCH = 3;
// const SOURCE_TYPE_CART_UPSELL = 4;
// const SOURCE_TYPE_RECOMMENDED = 5;

export var SOURCE_TYPE_BEST_SELLER = 6; // const SOURCE_TYPE_BRAND = 7;
// const SOURCE_TYPE_BUY = 8;
// const SOURCE_TYPE_SIMILAR = 9;
// const SOURCE_TYPE_JUST_BOUGHT = 10;
// const SOURCE_TYPE_CLUB_CATCH_LANDING = 11;
// const SOURCE_TYPE_CROSS_SELL = 12;
// const SOURCE_TYPE_BANNER_TILE = 13;
// const SOURCE_TYPE_COUPON = 14;
// const SOURCE_TYPE_CATEGORY_DROP_DOWN = 15;
// const SOURCE_TYPE_DAILY_DEAL = 16;

var SOURCE_TYPE_EMARSYS_POPULAR = 17;
var SOURCE_TYPE_EMARSYS_PERSONAL = 18;
export var SOURCE_TYPE_EMARSYS_HOME = 19;
var SOURCE_TYPE_EMARSYS_RECENTLY_VIEWED = 20; // const SOURCE_TYPE_SELLER_STORE = 21;
// const SOURCE_TYPE_WISHLIST = 22;
// const SOURCE_TYPE_SEARCH_ATTR = 23;
// const SOURCE_TYPE_SELLER_PRODUCT_LIST = 24;
// const SOURCE_TYPE_CART_UPSELL_SINGLE = 25;
// const SOURCE_TYPE_FEATURED_DEALS = 26;
// const SOURCE_TYPE_CATEGORY_VISUALISER = 27;

export var SOURCE_TYPE_EVENT_SINGLE_PRODUCT = 28; // const SOURCE_TYPE_NO_RESULT_SUGGESTION = 29;
// const SOURCE_TYPE_CERTONA_SUGGESTION = 30;
// const SOURCE_TYPE_SEARCH_SAJARI = 32;
// const SOURCE_TYPE_SEARCH_SAJARI_BEST_SELLER = 33;
// const SOURCE_TYPE_ORDER_NOTIFICATION = 34;
// const SOURCE_TYPE_LANDING_PAGE = 35;
// const SOURCE_TYPE_CART_UPSELL_SINGLE_REFERRAL = 36;
// const SOURCE_TYPE_SEARCH_ALGOLIA = 37;
// const SOURCE_TYPE_SEARCH_ALGOLIA_BEST_SELLER = 38;

var SOURCE_TYPE_EMARSYS_CATEGORY = 39; // const SOURCE_TYPE_DEALS = 40;
// const SOURCE_TYPE_INSIDER_PERSONAL = 41;

export var SOURCE_TYPE_HOME_PAGE_RECENTLY_VIEWED = 42; // const SOURCE_TYPE_INSIDER_CATEGORY = 43;

export var SOURCE_TYPE_CATCH_PRODUCT_RECOMMENDER = 44; // const SOURCE_TYPE_TRENDING_PRODUCTS = 45;
// const SOURCE_TYPE_FILTER_GROUP_ALGOLIA = 47;
// const SOURCE_TYPE_FILTER_GROUP_SAJARI = 48;

export var SOURCE_TYPE_CART_UPSELL_RECOMMENDED = 49;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_FBT = 50;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_SIMILAR_PRODUCT_DESC_ONLY = 51;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_EVENTS = 53; // const SOURCE_TYPE_SEARCH_IN_CATEGORY_SAJARI = 55;
// const SOURCE_TYPE_SEARCH_IN_CATEGORY_ALGOLIA = 56;
// const SOURCE_TYPE_TRUE_FIT = 57;
// const SOURCE_TYPE_WISHLIST_QUICKVIEW = 58;

export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_PAV = 59;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_EVENTS_V2 = 60; // const SOURCE_TYPE_HOME_PAGE_RECOMMENDER_BI_TRENDING_PRODUCTS = 61;
// Includes brands: Apple

export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_BRAND = 68; // OnePass

export var SOURCE_TYPE_ONEPASS_HERO_EVENT = 69; // Shop

export var SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_FEATURED_EVENT_PRODUCTS = 71;
export var SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_BEST_SELLER_PRODUCTS = 72;
export var SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_RECOMMENDED_PRODUCTS = 73;
export var SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_TRENDING_PRODUCTS = 74; // Includes sellers: Dyson

export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_SELLER = 109;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_SIMILAR_PRODUCT_IMAGE_ONLY = 81;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_SIMILAR_PRODUCT_IMAGE_DESC = 82;
export var SOURCE_TYPE_COPURCHASED_PRODUCTS_RECOMMENDER = 83;
export var SOURCE_TYPE_PRODUCT_PAGE_PERSONALISED_RECOMMENDER = 87; // export const SOURCE_TYPE_SEARCH_IN_BRAND_SAJARI = 88;

export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_PAV_RERANK_INCOMPATIBLE = 89;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_PAV_REMOVE_INCOMPATIBLE = 90;
export var SOURCE_TYPE_SEARCH_IN_CUSTOMER_CATEGORY_SAJARI = 91;
export var SOURCE_TYPE_SHOP_PAGE_RECENTLY_VIEWED = 92;
export var SOURCE_TYPE_HOME_PAGE_NEW_FOR_YOU_RECOMMENDER = 93;
export var SOURCE_TYPE_SHOP_PAGE_NEW_FOR_YOU_RECOMMENDER = 94;
export var SOURCE_TYPE_FOMO_RECOMMENDER = 95;
export var SOURCE_TYPE_ADS_BANNERS_COMPONENT = 96;
export var SOURCE_TYPE_BEST_SELLER_PRODUCT_COMPONENT = 97;
export var SOURCE_TYPE_ONE_PASS_COMPONENT = 98;
export var SOURCE_TYPE_POPULAR_DEALS_COMPONENT = 99;
export var SOURCE_TYPE_CATEGORY_BASED_PRODUCT_RECOMMENDER = 100;
export var SOURCE_TYPE_PERSONALISED_CATEGORY_RECOMMENDER = 101;
export var SOURCE_TYPE_TRENDING_CATEGORY_RECOMMENDER = 102;
export var SOURCE_TYPE_TOP_RATED_PRODUCT_RECOMMENDER = 103;
export var SOURCE_TYPE_SHOP_PAGE_CATEGORY_BASED_PRODUCT_RECOMMENDER = 104;
export var SOURCE_TYPE_SHOP_PAGE_TOP_RATED_PRODUCT_RECOMMENDER = 105;
export var SOURCE_TYPE_LATEST_DEALS_COMPONENT = 106;
export var SOURCE_TYPE_NEW_HOME_PAGE_RECENTLY_VIEWED = 107;
export var SOURCE_TYPE_NEW_HOME_PAGE_PERSONALISED_EVENT = 108;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_DYSON = 109;
export var SOURCE_TYPE_TOP_BRANDS_RECOMMENDER = 110;
export var SOURCE_TYPE_PERSONALISED_TOP_BRANDS_RECOMMENDER = 111;
export var SOURCE_TYPE_CART_CO_PURCHASED_PRODUCT_RECOMMENDER = 112;
export var SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_SIMILAR_PRODUCT_IMAGE_CAT = 113;
export var SOURCE_TYPE_TOP_PICKS_RECOMMENDER = 114; // Enhanced Searchbox

export var SOURCE_TYPE_ENHANCED_SEARCH_BOX = 115;
export var SOURCE_TYPE_ENHANCED_SEARCH_BOX_TRENDING_PRODUCTS = 116;
export var SOURCE_TYPE_ENHANCED_SEARCH_BOX_RECENTLY_VIEWED = 117;
export var SOURCE_TYPE_ENHANCED_SEARCH_BOX_RECENT_SEARCH_TERM = 118;
export var SOURCE_TYPE_ENHANCED_SEARCH_BOX_TRENDING_TERMS = 119; // Seasonal Cart Upsell recommender

export var SOURCE_TYPE_SEASONAL_CART_UPSELL_RECOMMENDED = 120; // Retail media

export var SOURCE_TYPE_SEARCH_TOP_SPONSORED = 121;
export var SOURCE_TYPE_SPONSORED_RECOMMENDER = 122; // const SOURCE_TYPE_GOOGLE_SHOPPING = 123;
// Search no results page

export var SOURCE_TYPE_SEARCH_NO_RESULTS = 124;
export var SOURCE_TYPE_SEARCH_TERM_RECOMMENDER = 125; // export const SOURCE_TYPE_ORGANIC_EXTERNAL_SITEMAP_SEARCH = 126;
// Retail media phase 2

export var SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER = 127;
export var SOURCE_TYPE_SEARCH_BOTTOM_SPONSORED_BANNER = 128;
export var SOURCE_TYPE_PRODUCT_PAGE_SPONSORED_BANNER = 129;
export var SOURCE_TYPE_HOMEPAGE_SPONSORED_BANNER = 130;
export var SOURCE_TYPE_HOMEPAGE_SPONSORED_RECOMMENDER = 131; // Mega Menu

export var SOURCE_TYPE_MEGA_MENU_ADULT_SHOP = 132; // Constructor

export var SOURCE_TYPE_CONSTRUCTOR_SEARCH = 133;
export var SOURCE_TYPE_CONSTRUCTOR_SEARCH_TRENDING_PRODUCTS = 134;
export var SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENTLY_VIEWED = 135;
export var SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENT_SEARCH_TERM = 136;
export var SOURCE_TYPE_CONSTRUCTOR_SEARCH_TRENDING_TERMS = 137; // Adding a new Emarsys source type? Also see _productItem.html.twig

export var SOURCE_TYPES_EMARSYS = [SOURCE_TYPE_EMARSYS_POPULAR, SOURCE_TYPE_EMARSYS_PERSONAL, SOURCE_TYPE_EMARSYS_HOME, SOURCE_TYPE_EMARSYS_RECENTLY_VIEWED, SOURCE_TYPE_EMARSYS_CATEGORY];