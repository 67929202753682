import React, { useContext } from "react";
import { Box, Link, Text } from "@catchoftheday/cg-components";
import { COPY_GENERIC_ERROR } from "../../../const/index.js";
import { CustomerContext } from "../../../contexts/CustomerContext/CustomerContext.jsx";
import { PersonalDetailsFormContainer } from "../PersonalDetailsFormContainer/PersonalDetailsFormContainer.jsx";
import { PersonalDetailsContainerPlaceholder } from "./PersonalDetailsContainerPlaceholder.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PersonalDetailsContainer = function PersonalDetailsContainer(_ref) {
  var host = _ref.host;

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data,
      loading = _useContext.loading;

  if (loading) return ___EmotionJSX(PersonalDetailsContainerPlaceholder, null);

  if (!customer) {
    return ___EmotionJSX(Text, null, COPY_GENERIC_ERROR);
  }

  var email = customer.email,
      phone = customer.phone,
      firstName = customer.firstName,
      lastName = customer.lastName;
  return ___EmotionJSX(Box, {
    pb: 8
  }, ___EmotionJSX(PersonalDetailsFormContainer, {
    host: host,
    customer: {
      email: email,
      phone: phone,
      firstName: firstName,
      lastName: lastName
    }
  }), ___EmotionJSX(Text, {
    fontSize: "xs",
    mt: 7,
    mb: 0
  }, "To see how we manage your personal information and other details, please see our ", ___EmotionJSX(Link, {
    href: "/privacy"
  }, "Privacy Policy"), " and", " ", ___EmotionJSX(Link, {
    href: "/privacy#collection"
  }, "Collection Statement")));
};