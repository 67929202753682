import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useContext, useEffect } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { cookies } from "@catchoftheday/utilities";
import { AUTH_FLOW_TOGGLE_COOKIE_NAME } from "../../consts";
export var MAX_RE_AUTH_RETRIES = 3;
export var useInitAuthFlow = function useInitAuthFlow() {
  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["new_auth_flow_may_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      experimentsLoading = _getExperiments.loading;

  useEffect(function () {
    if (experimentsLoading || !experiment) {
      return;
    }

    var isInNewAuthFlow = experiment.bucket === 1;
    cookies.set(AUTH_FLOW_TOGGLE_COOKIE_NAME, isInNewAuthFlow ? "1" : "0");
  }, [experiment, experimentsLoading]);
  return null;
};