// Recommender event_type
export var EVENT_RECOMMENDER_EVENT_TYPE = "recommended_bi"; // Product list interaction types

export var PRODUCT_LIST_INTERACTION_TYPES = {
  click: 1,
  option: 2,
  addToCart: 3
}; // OnePass

export var ONEPASS_CATEGORY = "OnePass";
export var ONEPASS_ACTION_UPSELL_NUDGE = "Upsell Nudge";
export var ONEPASS_ACTION_UPSELL_MODAL = "Upsell Modal";
export var ONEPASS_ACTION_UPSELL_MODAL_HEADER = "Upsell Modal Header";
export var ONEPASS_ACTION_REWARD = "Reward";
export var ONEPASS_ACTION_ACCOUNT_MANAGEMENT = "OnePass Management";
export var ONEPASS_NOTIFICATION_LABEL_SUCCESS = "Success Message";
export var ONEPASS_NOTIFICATION_LABEL_ERROR = "Error Message";
export var ONEPASS_ACTION_MIGRATION_RESULT = "Migration Result";
export var ONEPASS_ACTION_MIGRATION_MODAL = "Migration Modal";
export var ONEPASS_ACTION_MIGRATION_NUDGE = "Migration Nudge";
export var ONEPASS_ACTION_LINKING_MODAL = "Linking Modal";
export var ONEPASS_ACTION_LINKING_EXIST_MODAL = "Linking Exist Modal";
export var ONEPASS_ACTION_LINKING_RESULT = "Linking Result";
export var ONEPASS_ACTION_UPSELL_RESULT = "Upsell Result";
export var ONEPASS_MIGRATION_FLASH_MSG_LABEL_SUCCESS = "Success Message";
export var ONEPASS_MIGRATION_FLASH_MSG_LABEL_ERROR = "Error Message"; // DeliveryEstimate

export var DELIVERY_ESTIMATE_IMPRESSION = "delivery-estimate-impression"; // Retail media

export var SNOWPLOW_SESSION_ID = "sp_session_id"; // Tactical category

export var TACTICAL_CATEGORY_CATEGORY = "Tactical Category";