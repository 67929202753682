/** Auth handlers */
export var AUTH_TOKEN_COOKIE_NAME = "CAT";
export var AUTH_TOKEN_EXPIRY_COOKIE_NAME = "CAT_EXP";
export var AUTH_FLOW_TOGGLE_COOKIE_NAME = "auth_v3"; // Set expiry of auth cookies to just under 30 days.
// This acts as our inactivity lifetime.
// This is so that if a user's got an expired CAT, we'll still be able to do a silent refresh using the refresh token.

export var AUTH_SESSION_ROLLING_DURATION_IN_S = 29 * 24 * 60 * 60;
export var AUTH_SESSION_ABSOLUTE_DURATION_IN_S = 30 * 24 * 60 * 60;
export var AUTH_COOKIE_EXPIRY_IN_MS = AUTH_SESSION_ROLLING_DURATION_IN_S * 1000;
export var PHP_SESSION_COOKIE = "PHPSESSID";
export var REMEMBER_ME_COOKIE_NAME = "_c_rem";
export var CUSTOMER_ID_HEADER_NAME = "x-cg-customer-id";
export var CUSTOMER_UUID_COOKIE_NAME = "cuuid";
/** Stepup handlers */

export var STEPUP_TOKEN_COOKIE_NAME = "CST";
export var STEPUP_TOKEN_AUTHORISATION_HEADER_NAME = "x-cg-step-up-authorization";
export var STEPUP_COMPLETE_QUERY_PARAM = "stepup_complete";
export var STEPUP_LOGIN_PROMPT = "step_up";
export var STEPUP_SESSION_KEY = "stepup_session";
export var STEPUP_SESSION_TTL_IN_S = 10 * 60;
/** Error messages */

export var FAILED_LOGIN_MESSAGE = "Login attempt failed. Please try again later.";
/** Blocked account page */

export var CATCH_LOGO_URL = "https://s.catch.com.au/static/catch/images/logo-83d9b21199.svg";
export var TERMS_AND_CONDITIONS_URL = "/terms";
export var CUSTOMER_SUPPORT_URL = "https://help.catch.com.au/s/articles?&catName=My_Account";
export var BLOCKED_ACCOUNT_TEXT_FRAGMENT = "blocked";
export var BLOCKED_ACCOUNT_PAGE_PATH = "/account-blocked";
/** New flow - auth endpoints */

export var REFRESH_TOKEN_ENDPOINT = "/auth/refresh";
export var LOGIN_ENDPOINT = "/auth/login";
export var LOGOUT_ENDPOINT = "/auth/logout";
export var STEPUP_ENDPOINT = "/auth/stepup";
export var STEPUP_PROXY_ENDPOINT = "/auth/stepup/proxy";
export var STEPUP_SESSION_ENDPOINT = "/auth/stepup/session";
/** useRefreshAccessToken */

export var REVALIDATE_HEADER_STATE_EVENT = "@auth/REVALIDATE_HEADER_STATE";