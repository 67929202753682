import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { range } from "lodash-es";
import tinycolor from "tinycolor2";
import { rem as polishRem } from "./utils/polished.js";
var onePass = {
  primary: "#9900f1",
  primaryLight: "#dea9ff",
  primaryLighter: "#f4e7ff",
  primaryDark: "#7400af",
  primaryDarker: "#4f0084",
  secondary: "#00e28f",
  secondaryLight: "#b0f4de",
  secondaryDark: "#00ba7e",
  error: "#CB1F18",
  textPrimary: "#3A006A",
  textPrimaryLight: "#CDBFD8",
  textPrimaryLighter: "#F4E7FF",
  textGrey: "#666666",
  textLight: "#F7F7F7"
};
var namedColors = {
  gold: "#ffb700",
  primary: "#008A00",
  primaryDark: "#1C721C",
  primaryLight: "#E5FFE5",
  secondary: "#0064E4",
  secondaryDark: "#0040FF",
  secondaryLight: "#61b3ff40",
  tertiary: "#F6B123",
  tertiaryDark: "#E1A203",
  tertiaryLight: "#ffe94d",
  error: "#E62229",
  errorDark: "#CA1B1B",
  errorLight: "#FCE9E9",
  warning: "#F6B123",
  warningDark: "#936100",
  warningLight: "#FFF6E5",
  text: "#222222",
  textSecondary: "#333333",
  textTertiary: "#737373",
  textWhite: "#FFFFFF",
  grey: "#DDDDDD",
  lightGrey: "#F0F0F0",
  darkGrey: "#979797",
  white: "#FFFFFF"
};
var brand = {
  primary: "#0064E4",
  primaryDark: "#0040FF",
  primaryLight: "#61b3ff40",
  secondary: "#000096",
  secondaryDark: "#00006A",
  tertiary: "#008A00",
  tertiaryDark: "#1C721C",
  tertiaryLight: "#E5FFE5",
  clubCatch: "#631778",
  clubCatchDark: "#4A1159",
  clubCatchLight: "#9C33B0",
  clubCatchSecondary: "#F0D612",
  clubCatchSecondaryDark: "#DCB209",
  catchEssentials: "#9BCB43",
  flybuys: "#007CC2",
  facebook: "#2553B4"
};
var clubCatch = {
  primary: brand.clubCatch,
  light: brand.clubCatchLight,
  transpBg: "rgba(99,23,120,0.9)"
};
var disabledOpacity = 0.7;
var button = {
  primary: namedColors.primary,
  primaryText: namedColors.textWhite,
  primaryHover: namedColors.primaryDark,
  primaryHoverText: namedColors.textWhite,
  primaryDisabled: tinycolor(namedColors.primary).desaturate(50).toString(),
  primaryDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  secondary: namedColors.secondary,
  secondaryText: namedColors.textWhite,
  secondaryHover: namedColors.secondaryDark,
  secondaryHoverText: namedColors.textWhite,
  secondaryDisabled: tinycolor(namedColors.secondary).desaturate(50).toString(),
  secondaryDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  tertiary: namedColors.tertiary,
  tertiaryText: namedColors.textWhite,
  tertiaryHover: namedColors.tertiaryDark,
  tertiaryHoverText: namedColors.textWhite,
  tertiaryDisabled: tinycolor(namedColors.tertiary).desaturate(50).toString(),
  tertiaryDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  error: namedColors.error,
  errorText: namedColors.textWhite,
  errorHover: namedColors.errorDark,
  errorHoverText: namedColors.textWhite,
  errorDisabled: tinycolor(namedColors.error).desaturate(50).toString(),
  errorDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  plain: "transparent",
  plainText: namedColors.text,
  plainHover: "transparent",
  plainHoverText: namedColors.text,
  plainDisabled: "transparent",
  plainDisabledText: tinycolor(namedColors.text).brighten(50).toString(),
  onePassPrimary: onePass.primary,
  onePassPrimaryText: namedColors.textWhite,
  onePassPrimaryHover: onePass.primaryDark,
  onePassPrimaryHoverText: namedColors.textWhite,
  onePassPrimaryDisabled: "#91819E",
  onePassPrimaryDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  onePassOutline: onePass.primaryDarker,
  onePassOutlineText: namedColors.textWhite,
  onePassOutlineHover: onePass.primaryDarker,
  onePassOutlineHoverText: namedColors.textWhite,
  onePassOutlineDisabled: tinycolor(onePass.primaryDarker).desaturate(50).toString(),
  onePassOutlineDisabledText: tinycolor(namedColors.textWhite).darken().toString(),
  onePassSecondary: onePass.secondary,
  onePassSecondaryText: onePass.textPrimary,
  onePassSecondaryHover: onePass.secondaryDark,
  onePassSecondaryHoverText: namedColors.textWhite,
  onePassSecondaryDisabled: onePass.secondaryLight,
  onePassSecondaryDisabledText: tinycolor(onePass.textPrimary).darken().toString()
};
var typography = {
  text: namedColors.text,
  textSecondary: namedColors.textSecondary,
  subText: namedColors.textTertiary,
  inactive: "#979797",
  link: namedColors.secondary,
  linkHover: namedColors.secondaryDark
};
var ui = {
  backgroundColor: "#FAFAFA",
  backgroundColorSecondary: "#FFFFFF",
  backgroundColorMobile: "#E8E8E8",
  borderColor: namedColors.grey,
  inactiveColor: "#979797",
  baseElement: "#fff",
  modalOverlay: "rgba(0,0,0,0.6)",
  error: namedColors.error,
  dropDownBg: "#e2eef9",
  dropDownText: "#0074d9",
  hintColor: "rgba(255,183,0,0.25)"
};

var colors = _objectSpread(_objectSpread({
  brand: brand,
  clubCatch: clubCatch,
  onePass: onePass,
  ui: ui,
  button: button
}, namedColors), {}, {
  typography: typography
});

export function generateTheme() {
  var useRem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var rem = useRem ? polishRem : function (val) {
    return val;
  };
  var borders = [0, "1px solid", "2px solid", "3px solid"]; // Translates to 320px, 768px, 1024px, 1280px with base font-size: 16px

  var breakpoints = ["20rem", "48rem", "64rem", "80rem"
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ];
  breakpoints.xs = "0rem";
  breakpoints.sm = breakpoints[0];
  breakpoints.md = breakpoints[1];
  breakpoints.lg = breakpoints[2];
  breakpoints.xl = breakpoints[3];
  var fonts = {
    "catch": "Biennale, Helvetica, Arial, sans-serif",
    onePass: "Montserrat, Helvetica, Arial, sans-serif"
  };
  var shadows = {
    dropDown: " 0px 2px 4px 0px rgba(0,0,0,.25)",
    button: "inset 0 0.25em 0.5em rgba(0,0,0,.25)",
    box: "0 2px 4px 0 rgba(0,0,0,0.50)",
    focus: "0 0 2px 2px rgba(0,0,0)",
    toolTip: "4px 4px 4px rgba(0,0,0,0.25)",
    card: "0px 1px 5px 0px rgba(0, 0, 0, 0.15)"
  };
  var fontSizes = {
    "2xs": rem("10px"),
    xs: rem("12px"),
    sm: rem("14px"),
    base: rem("16px"),
    lg: rem("18px"),
    xl: rem("20px"),
    "2xl": rem("24px"),
    "3xl": rem("30px"),
    "4xl": rem("36px"),
    "5xl": rem("42px"),
    "6xl": rem("48px"),
    "7xl": rem("60px"),
    "8xl": rem("80px")
  }; // TODO: (CT-6011)
  // Update font-weight definitions for frontend packages and cotd-website as per UX guidelines.
  // regular:  100
  // semibold: 400, 500
  // black:    600, 700, 800

  var fontWeights = {
    light: 100,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  };
  var letterSpacings = {
    normal: "0",
    wide: "0.125em"
  };
  var lineHeights = {
    tight: 1,
    normal: 1.35,
    loose: 1.5
  };
  var constants = {
    stickyHeaderHeight: {
      xs: "70px",
      md: "145px"
    }
  };
  var maxWidths = {
    "default": rem("1440px"),
    cotdWebsite: rem("1340px")
  };
  var maxHeights = {
    pageBanner: rem("192px")
  };
  var radii = {
    none: 0,
    "default": "4px",
    semi: "8px",
    sharpCorner: "12px",
    corner: "16px",
    roundedButton: "4em",
    rounded: "1000px"
  };
  var opacity = {
    disabled: disabledOpacity
  };
  var space = range(0, 129, 4).map(function (i) {
    return rem("".concat(i, "px"));
  });
  var transitions = {
    "default": "300ms ease-in-out"
  };
  var textStyles = {
    h1: {
      fontSize: fontSizes["3xl"],
      fontWeight: fontWeights.medium,
      lineHeight: lineHeights.normal
    },
    h2: {
      fontSize: fontSizes["2xl"],
      fontWeight: fontWeights.semibold,
      lineHeight: lineHeights.normal
    },
    h3: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.loose
    },
    h4: {
      fontSize: fontSizes.base,
      fontWeight: fontWeights.semibold,
      lineHeight: lineHeights.loose
    },
    h5: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.loose
    },
    h6: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.loose
    },
    label: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.loose,
      fontWeight: fontWeights.semibold,
      color: colors.typography.text
    },
    text: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.loose,
      fontWeight: fontWeights.light
    },
    small: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.light,
      lineHeight: lineHeights.tight
    },
    fine: {
      fontSize: fontSizes["2xs"],
      fontWeight: fontWeights.light,
      lineHeight: lineHeights.tight
    }
  }; // Z-Indices
  // 101 - Header element and global popup modal overlay

  var zIndices = [0, 1, 10, 50, 100, 101];
  return {
    // Core props
    borders: borders,
    breakpoints: breakpoints,
    colors: colors,
    fontSizes: fontSizes,
    fontWeights: fontWeights,
    letterSpacings: letterSpacings,
    lineHeights: lineHeights,
    radii: radii,
    space: space,
    textStyles: textStyles,
    shadows: shadows,
    zIndices: zIndices,
    fonts: fonts,
    // Extra props
    constants: constants,
    maxWidths: maxWidths,
    transitions: transitions,
    opacity: opacity,
    maxHeights: maxHeights
  };
}
export var defaultTheme = generateTheme();